<template>
  <div>
    <b-row>
      <b-col>
        <h2>Change Case State</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <b-form-group
          label="Case ID"
          label-for="caseID"
        >
          <b-form-input
            id="caseID"
            v-model="CaseID"
            :readonly="readOnly"
            placeholder=""
            name="caseID"
          />
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 1.5rem;">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          :disabled="!CaseID"
          @click="lookup()"
        >
          Lookup
        </b-button>
      </b-col>
    </b-row>
    <div v-if="editable">
      <b-row v-if="showOld">
        <b-col>
          <span>Old Case: {{ oldCaseNumber }}</span>
        </b-col>
      </b-row>
      <b-row v-if="showOld">
        <b-col>
          <span>Old State: {{ oldState }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span>Case: {{ caseObj.FileNumber }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span v-if="!showOld">State: {{ oldState }}</span>
          <span v-if="showOld">State: {{ caseObj.CaseStateCode }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <span class="state-text">Change Case State:</span>
        </b-col>
        <b-col md="1.5">
          <v-select
            id="state-select"
            v-model="caseObj.CaseStateCode"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="StatesList"
            placeholder=""
            name="state"
            :disabled="showOld"
          />
        </b-col>
        <b-col>
          <b-button
            id="change-state-btn"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            :disabled="showOld"
            @click="showChangeModal()"
          >
            Change State
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-modal
      v-model="modalShow"
      hide-backdrop
      size="md"
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>You are about to change the state this case is assigned to.</strong>
          </h2>
          <h3>Are you sure you want to do this?</h3>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="hideChangeModal()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="changeState()"
            >
              Change State
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      caseObj: {
        CaseStateCode: "",
      },
      CaseID: "",
      showOld: false,
      oldCaseNumber: "",
      oldState: "",
      editable: false,
      readOnly: false,
      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY', 'ZZ'
      ],
      modalShow: false,
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    showChangeModal() {
      this.modalShow = true;
    },
    hideChangeModal() {
      this.modalShow = false;
    },
    changeState() {
      const postData = {
        CaseID: this.CaseID,
        StateCode: this.caseObj.CaseStateCode,
      };
      apiService
          .post("case/change/state", postData)
          .then((response) => {
            if (response) {
              this.caseObj.FileNumber = response.data.FileNumber;
              this.showOld = true;
              this.showToast('success', 'top-center', 4000, 'The state this case is assigned to has been changed.');
            }
          })
      this.modalShow = false;
    },
    lookup() {
      this.editable = true;
      apiService
          .get("case/" + this.CaseID + "/detail")
          .then((response) => {
            this.showOld = false;
            this.oldState = response.data.CaseStateCode;
            this.oldCaseNumber = response.data.FileNumber;
            this.caseObj = response.data;
          })
    },
  }
}
</script>

<style scoped>
#state-select {
  min-width: 6rem;
}
#change-state-btn {
  height: 2.4rem;
  padding: 0.5rem 1.5rem;
}
.state-text {
  top: 0.3rem;
  position: relative;
}

</style>